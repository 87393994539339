module services {
    export module customs {
        export class goodsDeclarationLineService implements interfaces.customs.IGoodsDeclarationLineService {
            static $inject = ["$resource", "ENV"];

            constructor(private $resource: ng.resource.IResourceService, private ENV: interfaces.applicationcore.serverConfig) {
            }


            get(gsdId: number, dciId: number, version: number): ng.resource.IResourceClass<interfaces.customs.IGoodsDeclarationLine> {
                return this.$resource<interfaces.customs.IGoodsDeclarationLine>(this.ENV.DSP_URL + "GoodsDeclarationLine/Get", {
                    goodsDeclarationId: gsdId,
                    dciId: dciId,
                    version: version
                }, {
                        get: {
                            method: "GET",
                            isArray: false,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                        }
                    });
            }
            
            save(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/Save");
            }

            removeAddCode(dlaId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/RemoveAddCode", {
                    dlaId: dlaId
                });
            }

            removeAddInfo(dlaId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/RemoveAddInfo", {
                    dlaId: dlaId
                });
            }

            calculateGoodsDeclarationQuantities(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/CalculateGoodsDeclarationQuantities");
            }

            removeQuantity(dlsId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/RemoveQuantity", {
                    dlsId: dlsId
                });
            }

            removeDuty(dluId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/RemoveDuty", {
                    dluId: dluId
                });
            }

            removeLine(goodsDecLineId: number): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/RemoveLine", {
                    goodsDecLineId: goodsDecLineId
                });
            }

            calculateGoodsDeclarationDuties(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/CalculateGoodsDeclarationDuties");
            }

            linkPreviousProcedureLine(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/LinkPreviousProcedureLine");
            }            

            getPreviousProcedureLineGDViewModel(): ng.resource.IResourceClass<interfaces.customs.PreviousProcedureGoodsDeclarationLineGD> {
                return this.$resource<interfaces.customs.PreviousProcedureGoodsDeclarationLineGD>(this.ENV.DSP_URL + "GoodsDeclarationLine/GetPreviousProcedureLineGDViewModel", {
                    connectSearch: '@connectSearch',
                    goodsDeclarationId: '@goodsDeclarationId',
                    showAll : '@showAll',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            } 

            getPreviousProcedureLineCIViewModel(): ng.resource.IResourceClass<interfaces.customs.PreviousProcedureGoodsDeclarationLineCI> {
                return this.$resource<interfaces.customs.PreviousProcedureGoodsDeclarationLineCI>(this.ENV.DSP_URL + "GoodsDeclarationLine/GetPreviousProcedureLineCIViewModel", {
                    connectSearch: '@connectSearch',
                    consignmentId: '@consignmentId',
                    commercialInvoiceId: '@commercialInvoiceId',
                    showAll : '@showAll',
                    numberRecords: '@numberRecords',
                    pageNumber: '@pageNumber',
                }, {
                        query: {
                            method: 'GET',
                            isArray: true,
                            interceptor: { response: config.appResourceDateInterceptor.parseResponseDates },
                            params: {
                                numberRecords: 25,
                                pageNumber: 1,
                            }
                        }
                    });
            }     
            
            copyLinesFromPreviousProcedureLineCI(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/CopyLinesFromPreviousProcedureLineCI");
            }    
            
            copyLinesFromPreviousProcedureLineGD(): ng.resource.IResourceClass<interfaces.applicationcore.IMessageHandler> {
                return this.$resource<interfaces.applicationcore.IMessageHandler>(this.ENV.DSP_URL + "GoodsDeclarationLine/CopyLinesFromPreviousProcedureLineGD");
            }                 

        }
    }
    angular.module("app").service("goodsDeclarationLineService", services.customs.goodsDeclarationLineService);
}